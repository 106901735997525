var app
$(function () {
  app = new Vue({
    el: '#nav-container',
    data: function data() {
      return {
        isHome: window.location.pathname,
        isLoading: false,
        showAddress: false,
        provinceList: addressList,
        // 省市列表
        cityList: [],
        // 区列表
        streetList: [],
        // 街道列表
        provinceActive: null,
        // 省市激活样式
        cityActive: null,
        // 区激活
        streetActive: null,
        // 街道激活
        cityValue: $.cookie('areaName'),
        // 区值
        streetValue: '',
        // 街道值
        provinceValue: '',
        //省市值
        areaValue: $.cookie('areaName'),
        // 办理地区值
        areaNo: $.cookie('areaId'),
        //地区号
        queryTypeList: [],
        //search搜索出来的分类
        queryTypeStatus: false,
        searchVal: '',
        cityValueCopy: '',
        show: false,
        shower: false,
        apkShow: false,
        qrcodeUser: qrcodeUser,
        screen: document.body.clientWidth,
        userEr: false,
        //用户
        downEr: false,
        //下载
        sinaEr: false,
        //新浪
        aboutEr: false,
        //关于我们
        publicEr: false,
        //公众号
        serverUrl: '', //下载apk
        userUrl: '',
        find: false
      }
    },
    mounted: function mounted() {
      this.find = true
      this.isLoading = true
      var that = this
      // 屏蔽下载链接
      // that.getUriVer()
      window.onresize = function () {
        return (function () {
          window.screenWidth = document.body.clientWidth
          that.screen = window.screenWidth
          that.messageUpdate()
        })()
      }
    },
    methods: {
      //获取下载apk地址
      getUriVer: function getUriVer() {
        var _this = this
        return
        $.ajax({
          url:
            'https://package.fuqim.com/app/appUriVer.json?v=' +
            new Date().getTime(),
          success: function success(data) {
            _this.userUrl = data.user.android_url
            _this.serverUrl = data.server.android_url
          }
        })
      },
      //messageUpdate
      messageUpdate: function messageUpdate() {
        this.$nextTick(function () {
          $('#qrcodeUser,#weiqrcodeUser').attr('src', qrcodeUser)
        })
      },
      // 地址确认
      addressConfirm: function addressConfirm() {
        this.areaValue = this.provinceValue + this.cityValue + this.streetValue
        var expiresDate = new Date()
        expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        if (this.cityValue) {
          $.cookie('areaName', this.cityValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', this.cityValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          this.refreshData()
        } else if (this.provinceValue) {
          $.cookie('areaName', this.provinceValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', '', {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.cityValueCopy = ''
          this.cityValue = this.provinceValue
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          this.refreshData()
        } //  else {
        //     showAlert('#alert2', '市级地址为必选项')
        // }
      },
      // 选择省市
      selectProvince: function selectProvince(item, index) {
        this.areaNo = item.areaId
        this.provinceActive = index
        this.provinceValue = item.areaName
        this.streetList = []
        this.cityActive = null
        this.streetActive = null
        this.cityValue = ''
        this.streetValue = ''
        if (this.provinceValue === '\u5168\u56FD') this.cityList = []
        if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
          this.cityList = item.areaDictionaryVOList
        }
      },
      // 选择市区
      selectCity: function selectCity(item, index) {
        this.areaNo = item.areaId
        this.cityActive = index
        this.cityValue = item.areaName
        this.cityValueCopy = item.areaName
        this.streetActive = null
        this.streetValue = ''
        if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
          this.streetList = item.areaDictionaryVOList
        }
      },
      // 选择街道
      selectStreet: function selectStreet(item, index) {
        this.areaNo = item.areaId
        this.streetActive = index
        this.streetValue = item.areaName
      },
      // 清空地址
      clearAddress: function clearAddress() {
        this.cityList = []
        this.streetList = []
        this.provinceActive = null
        this.cityActive = null
        this.streetActive = null
        this.provinceValue = $.cookie('areaName')
        this.areaNo = $.cookie('areaId')
        this.cityValue = ''
        this.streetValue = ''
      },
      // 异步刷新招标中心列表以及指数数据
      refreshData: function refreshData() {
        if (typeof vmProject != 'undefined') {
          var index = $('.tab ul li.active').data('index')
          vmProject.getProjectList(index, null, 1, null, null)
          vmProject.getTrade()
        }
        if (typeof vm != 'undefined') {
          var city = ''
          if (this.cityValue === '\u5168\u56FD') {
            city = ''
          } else {
            city = this.cityValueCopy != '' ? this.cityValue : ''
          }
          vm.areaValue = this.provinceValue + city
        }
      },
      //搜索类别
      searchTypeFn: function searchTypeFn(e) {
        try {
          if (e.target.value != '') {
            this.throttle(this.queryTypeDataFn, null, 500, e.target.value)
          }
        } catch (error) { }
      },
      hideSearchType: function hideSearchType() {
        var _this = this
        setTimeout(function () {
          _this.queryTypeStatus = false
        })
      },
      getSearchType: function getSearchType(item) {
        window.location.href = '/product/' + item.productNo
      },
      //节流
      throttle: function throttle(fn, context, delay, text) {
        clearTimeout(fn.timeoutId)
        fn.timeoutId = setTimeout(function () {
          fn.call(context, text)
        }, delay)
      },
      // 修改参数zlk
      queryTypeDataFn: function queryTypeDataFn(val) {
        var _this = this
        var queryMap = {
          keyword: val,
          pageNo: 1,
          pageSize: 10
        }
        var queryMap = JSON.stringify(queryMap)
        $.post('/search', { queryMap: queryMap }, function (result) {
          var result = JSON.parse(result)
          if (result.content === null) {
            $('.oneq').show()
          } else {
            $('.oneq').hide()
          }
          if (result.content !== null) {
            if (result.code == 0) {
              var data = result.content.productsInfos
              if (data.length === 0) {
                $('.oneq').show()
              } else {
                $('.oneq').hide()
              }
              _this.queryTypeList = data
              _this.queryTypeStatus = true
            }
          }
        })
      },
      searchFn: function searchFn() {
        window.location.href =
          '/search?keyword=' + this.searchVal + '&searchTabindex=1'
      }
    }
  })
  $('.hadoop')
    .parent()
    .stop()
    .hover(
      function () {
        $('.hadoopView')
          .stop()
          .show()
      },
      function () {
        $('.hadoopView')
          .stop()
          .hide()
      }
    )
  $('.download-app').click(function () {
    toast("赋企猫APP正在努力开发中~您可以扫描屏幕右侧小程序二维码，使用小程序登录移动端。", 2000)
  })
  $('.hadoopView').click(function () {
    $('.hadoopView').hide()
  })
  //右边框动画
  $('.minTools').click(function () {
    var dataType = $(this).attr('data-type')
    if (dataType == 'open') {
      $(this).addClass('open')
      $('.rightSideBar').animate({ right: '0' }, 300)
      dataType = $(this).attr('data-type', 'close')
    } else {
      $(this).removeClass('open')
      $('.rightSideBar').animate({ right: '-46px' }, 300)
      dataType = $(this).attr('data-type', 'open')
    }
  })
  //出世进来检测屏幕大小
  if (app.screen > 1530) {
    $('.rightSideBar').animate({ right: 0 }, 300)
  } else {
    $('.rightSideBar').animate({ right: '-46px' }, 300)
  }
  //监测窗口大小
  $(window).resize(function () {
    if (app.screen > 1530) {
      $('.rightSideBar').animate({ right: 0 }, 300)
    } else {
      $('.rightSideBar').css('right', '-46px')
    }
  })
  if (!$.cookie('token')) {
    $('.dengluView1').show()
    $('.dengluView2').hide()
  } else {
    $('.dengluView2').show()
    $('.dengluView1').hide()
  }
  // 左侧导航 地址判断
  if ($.cookie('token')) {
    $('.sideBarTop li:nth-child(1) a').attr('href', '/users')
  } else {
    $('.sideBarTop li:nth-child(1) a').attr('href', '/login')
  }
  // 右侧导航
  $('#qrcodeUser,#weiqrcodeUser').attr('src', qrcodeUser)



  $('.aboutus-a').hover(
    function () {
      $('.about-us').css('display', 'flex')
      $('.servers').css('display', 'none')
    }
  )

  $('.about-us').hover(
    function () {
      $('.about-us').css('display', 'flex')
    },
    function () {
      $('.about-us').css('display', 'none')
    }
  )


  $('.servers-a').hover(
    function () {
      $('.servers').css('display', 'flex')
      $('.about-us').css('display', 'none')
    }
  )

  $('.servers').hover(
    function () {
      console.log('authSuccess', authSuccess)
      $('.servers').css('display', 'flex')
    },
    function () {
      $('.servers').css('display', 'none')
    }
  )
})
